import axios from "../utils/axios";

export function elecProdectList(params) {
    return  axios.get("/mobile_api/mobile/electricity_product_list",params)
}
export function elecPay(params) {
    return  axios.post("/mobile_api/mobile/new_elec_pay?mobile="+params.mobile,params)
}
export function elecOrderList(params) {
    return  axios.get("/mobile_api/mobile/elec_order_list?type="+params.status,params)
}
export function elecOrderDetail(params) {
    return  axios.get("/mobile_api/mobile/elec_order_detail?order_id="+params.order_id,params)
}
